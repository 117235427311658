.carousel-container{
    height: 75vh;
    margin-bottom: 100px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.carousel-image{
    height: 70vh;
    width: 100%; 
}
.slick-slider{
    z-index: 1;
}

body {
    padding-top: 12vh; 
  }


@media(max-width:500px){
    .carousel-container{
        height: 65vh;
    }
    .carousel-image{
        height: 60vh;
        width: 100%;
        border-radius: 10px; 
    }
  }