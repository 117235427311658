.newsletter{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.news-heading{
    background: linear-gradient(180deg, rgb(227, 227, 152),rgb(190, 243, 190),white);
    width: 80%;
    height: 200px;
}
.news-heading h1{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 50px;
}
.news-heading p{
    font-size: 25px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.email-field{
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    width: 730px;
    height: 70px;
    border-radius: 80px;
    border: 1px solid #e3e3e3;
}
.email-field input{
    width: 500px;
    margin-left: 30px;
    border: none;
    outline: none;
    color: #616161;
    font-family: Poppins;
    font-size: 16px;
}
.email-field button{
    width: 210px;
    height: 70px;
    border-radius: 80px;
    background: black;
    color:white;
    font-size: 16px;
    cursor: pointer;
}

@media(max-width:500px){
    .news-heading{
        height: 100px;
    }
    .news-heading h1{
        font-size: 18px;
    }
    .news-heading p{
        font-size: 10px;
    }
    .news-heading{
        width: 95%;
    }
    .email-field{
        width: 300px;
        height: 40px;
        border-radius: 80px;
        border: 1px solid #e3e3e3;
    }
    .email-field input{
        width: 200px;
       
    }
    .email-field button{
        width: 100px;
        height: 40px;
        border-radius: 20px;
        font-size: 10px;
        font-style: bold;
        cursor: pointer;
    }
} 


/* .newsletter{
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 background: linear-gradient(180deg,#85e779 0%,#e1ffea22 60%);
 height: 40vh;
 width:70%;
 margin:auto;
}
.news-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.news-heading p{
    color: #454545;
    font-size: 20px;
}
.newsletter h1{
    color: #454545;
    font-size: 55px;
    font-weight: 600;
}
.email-field{
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    width: 730px;
    height: 70px;
    border-radius: 80px;
    border: 1px solid #e3e3e3;
}
.email-field input{
    width: 500px;
    margin-left: 30px;
    border: none;
    outline: none;
    color: #616161;
    font-family: Poppins;
    font-size: 16px;
}
.email-field button{
    width: 210px;
    height: 70px;
    border-radius: 80px;
    background: black;
    color:white;
    font-size: 16px;
    cursor: pointer;
}

@media(max-width:500px){
    .newsletter h1{
        color: #454545;
        font-size: 32px;
        font-weight: 600;
    }
    .newsletter p{
        font-size: 16px;
        margin-bottom: 20px;
    }
    .newsletter{
        width:95%;
        margin-top: 70px;
        height: 30vh;
    }
    .email-field{
        width: 450px;
        height: 50px;
    }
    .email-field input{
        width: 300px;
    }
    .email-field button{
        height: 50px;
        width: 170px;
    }
} */