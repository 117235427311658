.bottom-offer{
    display: flex;
    background-color: rgb(23, 141, 23);
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 3.5vh;
    top: 96.5vh;
    justify-content: center;
    align-items: center;
}

.bottom-offer p{
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: white;
}
