.item{
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 450px;
    box-shadow: 0 20px 20px -15px black;
    border-radius: 20px;
    border: 0.1px solid grey;
    gap: 30px;
}
.main-item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 450px;
        border-radius: 20px ;
        background-color: whitesmoke;
}
.item img{
    height: 240px;
    width: 240px;
    border-radius: 80px;

}
.container{
    display: flex;
    flex-direction: column;
    gap: 5px;   
    width: 350px;
    height: 123px;
}
.container p{
    font-size: 18px;
    font-weight: 700;
    margin-left: 20px;
    margin-top: 8px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    align-self: center;
}
.item-prices{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.item-price-new{
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}
.item-price-old{
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}
.container button{
    background-color: rgb(32, 139, 205);
    color: white;
    width: 100px;
    height:40px;
    border: none;
    outline: none;
    border-radius: 15px;
    font-weight: 600;
    padding: 10px;
    box-shadow: 0 10px 10px -8px black;
}
.item-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 90px;
    margin-top: 10px;   
}
.photo-cont{
    margin-top: -40px;
    margin-bottom: 20px;
}
.pr-name p{
    color: rgb(2, 52, 2);
}
.desc{
    color: rgb(39, 147, 66);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


@media(max-width:500px){
    .container{
        width: 300px;
        height: 100px;
    }
    .item{
        height: 400px;
    }
    .main-item{
        display: flex;
        flex-direction: column;
        width: 300px;
        height: 450px;
        border-radius: 20px ;
    }
    .item img{
        height: 200px;
        width: 200px;
    }
    .container button{
        height:30px;
        border-radius: 10px;
        font-size: 15px;
        padding: 2px;
    }
    .item-buttons{
        gap: 75px;
        margin-top: 0;
    }
    .container p{
        font-size: 16px;
    }
    .photo-cont{
        margin-bottom: 13px;
    }
}
