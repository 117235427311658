.sidebar{
    margin-top: 10vh;
    margin-left: -80px;
    height:88vh;
    width: 40vh;
    position: fixed;
    transform: 0.5s;
    background-color: white;
    display: none;
}
.sidebar.visible{
    transform: translateX(0);
    display: block;
}
.sidebar a{
    text-decoration: none;
    font-size: 20px;
    padding-top:20px;
    padding-bottom:20px;
    color: black;
    transition: 0.3s;
    font-family: Georgia, 'Times New Roman', Times, serif;
    display: block;
    font-weight: bold;
}
.sidebar a:hover{
    background-color: #ddd;
}
 @media(max-width:500px){
    .sidebar{
        margin-top: 9vh;
    }
    .sidebar a{
        font-size: 16px;
    } 
  }

  @media(max-width:470px){
    .sidebar{
        margin-top: 9vh;
    }
  } 

  @media(max-width:430px){
    .sidebar{
        margin-top: 7vh;
    }
    .sidebar a{
        font-size: 16px;
    }   
  }

  @media(max-width:400px){
    .sidebar{
        margin-top: 8vh;
    }
    .sidebar a{
        font-size: 16px;
    }   
  }