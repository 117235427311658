.why{
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background: linear-gradient(180deg,#65e375,#e1ffea22 60%); */
}
.why-icon-2 img{
    height: 250px;
    width: 250px;
}
.why-icon-3 img{
    height: 250px;
    width: 250px;
}
.why h1{
    font-size: 50px;
    color: green;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.why-icon-3{
    margin-top: 30px;
    display: flex;
} 
.why-icon-2{
    display: flex;
}
@media(max-width:500px){
    .why h1{
        font-size: 20px;
        color: green;
    } 
    .why-icon-3 img{
        height: 120px;
        width: 120px;
    }
    .why-icon-2 img{
        height: 120px;
        width: 120px;
    }
}
