.footer{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:50px;
}
.footer-logo{
    display: flex;
    align-items: center;
    gap: 20px;
}
.footer-logo img{
    height: 120px;
    width: 90px;
}
.footer-logo p{
    color: #383838;
    font-size: 46px;
    font-weight: 700;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.footer-links{
    display: flex;
    list-style: none;
    gap: 50px;
    color: #252525;
    font-size: 20px;
}
.footer-links li{
    cursor: pointer;
}
.footer-social-icon{
    display: flex;
    gap: 20px;
}
.footer-social-icon img{
    height: 70px;
    width: 70px;
}
.footer-icons-container{
    padding: 10px;
    padding-bottom: 6px;
    color: #fbfbfb;
    border: 1px solid #ebebeb;
}
.footer-copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    margin-bottom: 30px;
    color: #1a1a1a;
    font-size: 20px;
}
.footer-copyright hr{
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 3px;
    background: #c7c7c7;
}

@media(max-width:500px){
    .footer{
        margin-top: 70px;
    }
    .footer-logo img{
        height: 80px;
        width: 50px;
    }
    .footer-logo p{
        font-size: 30px;
    }
    .footer-links{
        gap: 20px;
        font-size: 18px;
    }
    .footer-social-icon img{
        height: 40px;
        width: 40px;
    }
    .footer-copyright p{
        font-size: 16px;
    }
    .footer-copyright hr{
        width: 60%;
    }
    .footer-links li{
        font-size: 14px;
    }
}