.points{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    /* background: linear-gradient(180deg,#65e375,#e1ffea22 60%); */
}
.point-heading h1{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.points-container{
   display: flex;
   justify-content: center;
   align-items: center;
}
 .points-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:100px;
    margin-bottom: 100px;
    margin-top: 70px;
    text-align: center;
    
} 
.points-content-sec{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:100px;
    margin-bottom: 100px;
    margin-top: 70px;
    text-align: center;
    margin-left: 100px;
}
.points-container img{
    width: 70px;
    height:70px;
    align-self: center;    
}
.points-content p{
    font-size: medium;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.points-content-sec p{
    font-size: medium;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
#pHead{
    font-weight: bold;
}
.points-icon{
    justify-content: center;
    align-items: center;
}
@media(max-width:500px){
    
    .point-heading{
        display: flex;
        justify-content: center;
        align-items: center ;
        text-align: center;
    }
    .point-heading h1{
        font-size: 30px;
    }
    .points-container{
        margin-top: 50px;
        margin-bottom: 80px;
    }
    .points-content{
        display: block;
        margin:30px;
    }
    .points-content-sec{
        display: block;
        margin:30px;
    }
    .points-content .points-content-sec p{
        font-size: 11px;
    }
    .sec-point{
        margin-top: 40px;
        margin-bottom: 50px;
    }
}