.navbar{
    position: fixed;
    display: flex;
    flex-direction: column;
    width:100%;
    height:12vh;
    border-bottom: 0.1px solid green;
    z-index: 1000;
    background-color: white;
    top: 0;
}
.nav-logo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav-logo img{
    height: 90px;
    width: 80px;
}
.nav-logo p{
    color: #043715;
    font-size: 25px;
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;   
}
.nav-login-cart{
    display: flex;
    align-items: center;
}
.nav-login-cart img{
    height: 45px;
    width: 45px;
}
.nav-cart-count{
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -35px;
    margin-left: -55px;
    border-radius: 11px;
    font-size: 14px;
    background: red;
    color: white;
}
.offerSection{
    background-color: rgb(245, 92, 57);
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.offerSection p{
    font-weight: bold;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.bar{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 560px;
}
.menu-icon{
    display: flex;
    color: green;
    font-size: 60px;
    cursor: pointer;
}
@media(max-width:500px){
    .bar{
        gap: 85px;
        margin-top: 10px;
    }
    .nav-logo img{
        height: 60px;
        width: 55px;
    }
    .nav-logo p{
        font-size: 14px;
        font-weight: 1300;
    }
    .nav-login-cart img{
        height: 30px;
        width: 30px;
    }
    .nav-cart-count{
        margin-left: -12px;
        width: 16px;
        height: 16px;
    }
    .menu-icon{
        font-size: 30px;
    }

}

@media(max-width:470px){
    .bar{
        gap: 85px;
    }
}
@media(max-width:430px){
    .bar{
        gap: 85px;
    }
    .nav-logo img{
        height: 50px;
        width: 40px;
    }
    .nav-logo p{
        font-size: 22px;
        font-weight: bold;
    }
    .nav-menu{
        height: 60px;
        width:80%;
        position: absolute;
        justify-content: center;
        align-items: center;
        top:100px;
        border-radius: 10px;
    }
    .nav-menu li{
        font-size: 10px;
    }
    .nav-cart-count{
        margin-left: -10px;
    }
}
@media(max-width:400px){
    .bar{
        gap: 85px;
    }
}
@media(max-width:370px){
    .bar{
        gap: 80px;
    }
    .nav-logo img{
        height: 45px;
        width: 37px;
    }
    .nav-logo p{
        font-size: 20px;
    }
    .nav-menu{
        height: 60px;
        width:80%;
        position: absolute;
        justify-content: center;
        align-items: center;
        top:100px;
        border-radius: 10px;
    }
    .nav-menu li{
        font-size: 8px;
    }
    .nav-cart-count{
        margin-left: -10px;
    }
    .nav-menu-visible{
        margin-left: 10px;
    }
}